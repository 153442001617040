import SectionTitleWithLine from '@/components/generic/SectionTitleWithLine'
import Layout from '@/components/layouts/Layout'
import { SectionContainer } from '@/components/styled/Container'
import InnerContainer from '@/components/styled/InnerContainer'
import StyledLink from '@/components/styled/Link'
import { Categories, Category, NewsBar, TabTitle } from '@/pages/NewsIndex'
import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import NewsList from '@/components/pages/news/NewsList'
import { media } from '@/styles/media'

export default function AnnouncementPagination({ data, pageContext }) {
  if (!data) {
    return <div>Loading news...</div>
  }

  const { t } = useTranslation()

  return (
    <Layout
      pageTitle={t('seo.news.title')}
      pageDescription={t('seo.news.meta_description')}
      pageCanonical={`https://ichigo.com/news/announcement/${pageContext.currentPage}`}
    >
      <NewsBar>
        <SectionContainer
          customCss={css`
            ${media.tabletLandscapeDown} {
              padding: 0 16px !important;
            }
          `}
        >
          <InnerContainer style={{ flexDirection: 'column', margin: '0 auto' }}>
            <Categories>
              <StyledLink to="/news">
                <Category>{t('news_category.all-news')}</Category>
              </StyledLink>
              <StyledLink to="/news/press-release">
                <Category>{t('news_category.press-release')}</Category>
              </StyledLink>
              <StyledLink to="/news/media-coverage">
                <Category>{t('news_category.media-coverage')}</Category>
              </StyledLink>
              <Category className="active">
                {t('news_category.announcement')}
              </Category>
            </Categories>
          </InnerContainer>
        </SectionContainer>
      </NewsBar>

      <SectionContainer
        customCss={css`
          padding-top: 96px;
          padding-bottom: 168px;
          overflow: hidden;

          ${media.tabletLandscapeDown} {
            padding: 72px 16px !important;
            padding-top: 120px !important;
          }
        `}
      >
        <InnerContainer
          style={{
            flexDirection: 'column',
            margin: '0 auto',
            position: 'relative'
          }}
        >
          <TabTitle>{t('news_category.announcement')}</TabTitle>
          <SectionTitleWithLine
            text="LATEST NEWS"
            secondText="最新ニュース"
            gap="32px"
            centeredOnMobile
            bigTextOnMobile
          />

          <NewsList
            paginationBaseUrl="/news/announcement"
            data={data.allPrismicNew}
            pageContext={pageContext}
          />
        </InnerContainer>
      </SectionContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int) {
    allPrismicNew(
      skip: $skip
      limit: 5
      filter: { data: { category: { eq: "announcement" } } }
      sort: { fields: [data___article_date], order: DESC }
    ) {
      edges {
        node {
          data {
            image {
              url
            }
            heading {
              text
            }
            translated_heading {
              text
            }
            subheading {
              text
            }
            translated_subheading {
              text
            }
            is_internal_article
            category
            original_language
            slug
            date
            excerpt {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
  }
`
